import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Heading1 } from '@dimatech/shared/lib/components/typography';
import { Card, CardBody, ViewHeader, } from '@dimatech/shared/lib/components/workspace';
import { flags } from '@dimatech/shared/lib/feature-flags';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CommonRoles } from 'models';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthenticationContext } from '../authentication';
import { CustomerAccountInfo } from '../authentication/components/CustomerAccountInfo';
import { AccountAdmins } from './components/AccountAdmins';
import { BillingInfo } from './components/BillingInfo';
import { LocaleInfo } from './components/LocaleInfo';
import { Logo } from './components/Logo';
export var AdministrateAccount = function () {
    var _a;
    var t = useTranslation().t;
    var accessToken = useContext(AuthenticationContext).accessToken;
    var isLocaleSelectionEnabledFlagOn = useFlags()[flags.permanent.shared.isLocaleSelectionEnabled];
    return (_jsxs(_Fragment, { children: [_jsx(ViewHeader, { children: _jsx(Heading1, { children: t('Administrate.Account.Title') }) }), _jsx(CustomerAccountInfo, {}), _jsx(Card, { children: _jsx(CardBody, { children: _jsx(AccountAdmins, {}) }) }), isLocaleSelectionEnabledFlagOn && (_jsx(Card, { children: _jsx(CardBody, { children: _jsx(LocaleInfo, {}) }) })), _jsx(Card, { children: _jsx(CardBody, { children: _jsx(BillingInfo, {}) }) }), accessToken.isInRole(CommonRoles.GlobalAdmin) && (_jsx(Card, { children: _jsx(CardBody, { children: _jsx(Logo, { customerId: accessToken.customerId, customerName: (_a = accessToken.user) === null || _a === void 0 ? void 0 : _a.currentCustomer.name }) }) }))] }));
};
AdministrateAccount.displayName = 'AdministrateAccount';
